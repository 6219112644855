<template>
  <a
    id="AC"
    href="#AC"
    accesskey="C"
    title="主要內容區塊[快捷鍵Alt+C]" tabindex="0"
    class="sr-only sr-only-focusable"
  >
    ::::
  </a>
</template>

<script>

export default {
  name: 'MainContain',
  data () {
    return {
    }
  }
}
</script>
